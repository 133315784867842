export const OPTIMIZELY_KEY_MULTIPLE_PROFILES = 'bees_force_frontend_multiple_profiles';
export const OPTIMIZELY_KEY_PHOTO_TASK = 'photo_task_front_end_flag';
export const OPTIMIZELY_KEY_SPONSORED_TASK = 'tasks-business-field-is-sponsored';
export const OPTIMIZELY_KEY_FORCE_HUB_MIGRATION = 'vendors_with_hub_access';
export const OPTIMIZELY_KEY_TASK_CATEGORIES = 'bees-hub-force-mfe-task-categories';
export const OPTIMIZELY_KEY_PHOTO_TASK_SPONSORED_TASK = 'photo-task-field-is-sponsored';
export const OPTIMIZELY_KEY_FILTER_SPONSORED = 'tasks_management_filter_tasks_sponsored';
export const OPTIMIZELY_KEY_TASK_CANCELLATION = 'bees-hub-force-mfe-task-cancellation';
export const OPTIMIZELY_KEY_VOLUME_TASK = 'bees-hub-force-mfe-volume-task';
export const OPTIMIZELY_FRONTLINE_SIDEBAR = 'bees-hub-frontline-sidebar';
