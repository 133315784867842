import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { useHubTheme } from 'admin-portal-shared-services';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConditionalSidebar } from './components/ConditionalSidebar/ConditionalSidebar';
import FallbackError from './components/FallbackError';
import { FORCE_HUB_CLASSNAME_PREFIX, FORCE_HUB_THEME } from './consts/theme';
import AppHeaderProvider from './providers/AppHeaderProvider';
import AppThemeProvider from './providers/AppThemeProvider';
import { EnvConfig, EnvProvider } from './providers/EnvProvider/EnvProvider';
import { IntlProvider } from './providers/IntlProvider';
import OptimizelyProvider from './providers/OptimizelyProvider';
import QueryProvider from './providers/QueryProvider';
import SegmentProvider from './providers/SegmentProvider';
import UserInfoProvider from './providers/UserInfoProvider';
import { store } from './redux/store';
import Router from './Router';

export default function App(envProps: EnvConfig): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: FORCE_HUB_CLASSNAME_PREFIX,
    seed: FORCE_HUB_CLASSNAME_PREFIX,
  });

  const [hubTheme] = useState<ThemeProviderType['theme']>(FORCE_HUB_THEME);
  useHubTheme(hubTheme);

  return (
    <ErrorBoundary FallbackComponent={FallbackError}>
      <QueryProvider>
        <EnvProvider env={envProps}>
          <ReduxProvider store={store}>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={hubTheme}>
                <AppThemeProvider>
                  <IntlProvider>
                    <OptimizelyProvider>
                      <AppHeaderProvider>
                        <UserInfoProvider>
                          <SegmentProvider>
                            <BrowserRouter>
                              <ConditionalSidebar />
                              <Router />
                            </BrowserRouter>
                          </SegmentProvider>
                        </UserInfoProvider>
                      </AppHeaderProvider>
                    </OptimizelyProvider>
                  </IntlProvider>
                </AppThemeProvider>
              </ThemeProvider>
            </StylesProvider>
          </ReduxProvider>
        </EnvProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}
