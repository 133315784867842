import { useDecision } from "@optimizely/react-sdk";
import { OPTIMIZELY_FRONTLINE_SIDEBAR } from "../../consts/optimizely";
import Sidebar from "../Sidebar";

function ConditionalSidebar(): JSX.Element {
    const [frontlineSidebarFlag, frontlineSidebarFlagReady] = useDecision(
        OPTIMIZELY_FRONTLINE_SIDEBAR
    );

    const isFrontlineSidebarFlagEnabled = frontlineSidebarFlagReady && frontlineSidebarFlag.enabled;

    return isFrontlineSidebarFlagEnabled ? null : <Sidebar />;
}

export { ConditionalSidebar };
